import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  useMediaQuery,
  Typography,
  GridList,
  GridListTile,
  Container,
} from '@material-ui/core';
import { SectionHeader, SectionHeaderWithTag } from 'components/molecules';
import { Image } from 'components/atoms';
import { useStaticQuery, graphql, Link } from 'gatsby';
import SdgImage from 'assets/images/sdg/home_page_sdg.png';

const useStyles = makeStyles(theme => ({
  quickStartSection: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    },
  },
  fontWeight900: {
    fontWeight: 900,
  },
  editor: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    borderRadius: theme.spacing(1 / 2),
    width: '100%',
  },
  gridList: {
    width: 500,
    height: 300,
  },
  ImageContainer: {
    width: '100%',
  },
  ImageObject: {
    width: '100% !important',
  },
  container: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
  },
}));

const ValueProps = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const data = useStaticQuery(graphql`
    query sdgGoals {
      fauna {
        allSdgs {
          data {
            logo_square_img
            _id
            name
            sdg_id
          }
        }
      }
    }
  `);

  const learnButton = (
    <Button
      size="large"
      variant="outlined"
      color="primary"
      component="a"
      href="/blog/the-un-sustainable-development-goals"
    >
      Learn about the goals
    </Button>
  );

  const searchButton = (
    <Button
      size="large"
      variant="outlined"
      color="primary"
      component="a"
      href="/all-solutions"
    >
      Search for partners
    </Button>
  );

  const comingSoonButton = (
    <Button
      size="large"
      variant="outlined"
      color="primary"
      component="a"
      href="/"
    >
      Coming Soon ...
    </Button>
  );

  return (
    <Container className={classes.container} {...rest}>
      <Grid container justifyContent="space-between" spacing={isMd ? 10 : 8}>
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            spacing={isMd ? 4 : 2}
            direction={isMd ? 'row' : 'column-reverse'}
          >
            <Grid
              item
              xs={12}
              container
              alignItems="left"
              md={6}
              data-aos={'fade-right'}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6} md={6}>
                  <Typography color="primary" variant="h3" gutterBottom>
                    200+
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    comitted companies
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography color="primary" variant="h3" gutterBottom>
                    400+
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    impact solutions
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography color="primary" variant="h3" gutterBottom>
                    40+
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    sustainability experts
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography color="primary" variant="h3" gutterBottom>
                    1
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    platform
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} data-aos={'fade-left'} alignItems="left">
              <SectionHeader
                label={<b>DISCOVER</b>}
                title="Find the right partners for your brand"
                subtitle="Search an open database of impact partners brought together, organized and expert-reviewed to save you time finding the needle in the haystack."
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
                align={isMd ? 'left' : 'center'}
                ctaGroup={[searchButton]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6} data-aos={'fade-right'}>
              <SectionHeader
                label={<b>BUILD</b>}
                title="Bring others into your mission"
                subtitle="Create a clear, transparent impact portfolio for your brand to accelerate partnerships and build trust with your consumers."
                align={isMd ? 'left' : 'center'}
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
                className={classes.quickStartSection}
                ctaGroup={[comingSoonButton]}
              />
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              xs={12}
              md={6}
              data-aos={'fade-left'}
            >
              <div className={classes.ImageContainer}>
                <img
                  src={`/images/home_page/build_img.png`}
                  alt="Build your impact portfolio"
                  className={classes.ImageObject}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
            <Grid
              item
              container
              alignItems="center"
              xs={12}
              md={6}
              data-aos={'fade-left'}
            >
              <div className={classes.ImageContainer}>
                <img
                  src={SdgImage}
                  alt="SDG Goal Image"
                  className={classes.ImageObject}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} data-aos={'fade-right'}>
              <SectionHeader
                label={<b>IMPACT</b>}
                title="Deliver positive impact towards 2030"
                subtitle="Businesses have a critical role to play in reaching the UN 2030 Development Goals. And winning businesses will have a clear strategy matching their purpose to the goals."
                align={isMd ? 'left' : 'center'}
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
                className={classes.quickStartSection}
                ctaGroup={[learnButton]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ValueProps;
