import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  useMediaQuery,
  Typography,
  Container,
  IconButton,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { CardJobCompany } from 'components/organisms';
import { truncateString } from 'helpers/helpers-string';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  categoryIconButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  themeColors: {
    color: theme.palette.primary.light,
  },
  wordHighlight: {
    color: 'green',
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const PartnersShowcase = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const data = useStaticQuery(graphql`
    query faunaCertifications {
      fauna {
        allSolutions(_size: 3) {
          data {
            name
            description
            logo_img
            _id
          }
        }
      }
    }
  `);

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <SectionHeader
            title={
              <span className={classes.wordHighlight}>Browse the database</span>
            }
            align="left"
            titleVariant="h2"
            titleProps={{ className: classes.fontWeight900 }}
          />
        </Grid>
        <Grid item xs={4}>
          <Link
            to={'/all-certifications'}
            className={clsx(
              'card-category-link__item',
              classes.categoryIconButton,
            )}
          >
            <Typography className={classes.verticalAlign}>See all</Typography>
            <IconButton className="card-category-link__icon-button">
              <ArrowRightAltIcon
                className={clsx(
                  'card-category-link__icon',
                  classes.themeColors,
                )}
              />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={1} xs={12}>
        {data.fauna.allCertifications.data.map(node => {
          const item = {
            companyLogo: node.logo_img,
            companyName: node.name,
            companyInfo: truncateString(node.description, 75),
            jobsCount: 'Used by x companies',
          };
          return (
            <Grid item xs={12} sm={6} md={4} data-aos={'fade-right'}>
              <CardJobCompany id={node._id} {...item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PartnersShowcase;
