import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Container,
  IconButton,
  Hidden,
  Box,
  Chip,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { CardSolution } from 'components/organisms';
import { useStaticQuery, graphql } from 'gatsby';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  grid: {
    justifyContent: 'center',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  container: {
    marginTop: '30px',
  },
  loadingContainer: {
    marginTop: '30px',
    height: '200px',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  categoryIconButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  themeColors: {
    color: theme.palette.primary.light,
  },
  wordHighlight: {
    color: 'primary',
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const ThemesShowcase = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query allThemes {
      fauna {
        allThemes(_size: 40) {
          data {
            _id
            name
          }
        }
      }
    }
  `);

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12}>
        <SectionHeader
          title="Your brand is unique. So is your impact."
          subtitle="Find partners for the themes and problems that matter to you company."
          align="center"
          titleVariant="h2"
          titleProps={{ className: classes.fontWeight900 }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.chipArea}>
          {data.fauna.allThemes.data.map((item, index) => (
            <Link to={'/all-solutions'} state={{ themes: [`${item._id}`] }}>
              <Chip
                key={index}
                color="primary"
                label={item.name}
                style={{
                  color: 'white',
                }}
              />
            </Link>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default ThemesShowcase;
