import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Container,
  IconButton,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { CardCampaign } from 'components/organisms';
import { truncateString } from 'helpers/helpers-string';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  categoryIconButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  themeColors: {
    color: theme.palette.primary.light,
  },
  wordHighlight: {
    color: 'red',
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const CampaignsShowcase = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query faunaCampaigns {
      fauna {
        allRelEntitiesCampaigns(_size: 3) {
          data {
            campaign {
              banner_img
              campaign_url
              description
              logo_img
              name
              _id
            }
            entity {
              name
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <SectionHeader
            title={
              <span className={classes.wordHighlight}>
                Campaigns
                <Typography
                  color="textPrimary"
                  variant="inherit"
                  component="span"
                >
                  {' '}
                  from brands that matter
                </Typography>
              </span>
            }
            align="left"
            titleVariant="h4"
            titleProps={{ className: classes.fontWeight900 }}
          />
        </Grid>
        <Grid item xs={4}>
          <Link
            to={'/all-campaigns'}
            className={clsx(
              'card-category-link__item',
              classes.categoryIconButton,
            )}
          >
            <Typography className={classes.verticalAlign}>See all</Typography>
            <IconButton className="card-category-link__icon-button">
              <ArrowRightAltIcon
                className={clsx(
                  'card-category-link__icon',
                  classes.themeColors,
                )}
              />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {data.fauna.allRelEntitiesCampaigns.data.map(node => {
          const item = {
            images: [
              { src: node.campaign.banner_img, alt: node.campaign.name },
            ],
            name: node.campaign.name,
            description: truncateString(node.campaign.description, 75),
            entity: node.entity.name,
          };
          return (
            <Grid item xs={12} sm={6} md={4} data-aos={'fade-right'}>
              <CardCampaign id={node._id} {...item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CampaignsShowcase;
