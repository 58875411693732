import React from 'react';
import {
  makeStyles,
  Divider,
  useMediaQuery,
  useTheme,
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { useStaticQuery, graphql, Link } from 'gatsby';
import {
  GetStarted,
  Features,
  ValueProps,
  Services,
  Hero,
  LatestStories,
  SidebarArticles,
  SolutionsShowcase,
  ThemesShowcase,
} from './components';
import { DescriptionCta } from 'components/molecules';

const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const data = useStaticQuery(graphql`
    query HomePageArticles {
      allContentfulBlogPost(limit: 4, filter: { datePosted: { ne: null } }) {
        nodes {
          datePosted
          title
          slug
          subtitle
          tags
          imageCover {
            id
            fluid {
              src
              srcSet
            }
          }
          author {
            authorName
            authorImage {
              fluid {
                src
                srcSet
              }
            }
          }
        }
      }
      fauna {
        allRssFeedsSortedByDate(_size: 8) {
          after
          before
          data {
            image {
              url
            }
            title
            link
            _ts
          }
        }
      }
    }
  `);

  const latestStories = data.allContentfulBlogPost.nodes.map(data => {
    return {
      cover: {
        src: data.imageCover.fluid.src,
        srcSet: data.imageCover.fluid.srcSet,
      },
      title: data.title,
      subtitle: data.subtitle,
      author: {
        photo: {
          src: data.author.authorImage.fluid.src,
          srcSet: data.author.authorImage.fluid.srcSet,
        },
        name: data.author.authorName,
      },
      date: data.datePosted,
      tags: data.tags ? data.tags : [],
      slug: data.slug,
    };
  });

  const sidebarArticles = data.fauna.allRssFeedsSortedByDate.data.map(data => {
    let postDate = new Date(data._ts / 1000);
    return {
      cover: {
        src: data.image.url,
      },
      title: data.title,
      date:
        postDate.getMonth() +
        '-' +
        postDate.getDate() +
        '-' +
        postDate.getFullYear(),
      tags: data.tags ? data.tags : [],
      link: data.link,
    };
  });

  return (
    <div>
      <Hero themeMode={themeMode} />
      <SectionAlternate className={classes.sectionAlternateNoPaddingTop}>
        <ValueProps />
      </SectionAlternate>
      <Section>
        <ThemesShowcase />
      </Section>
      <Section>
        <SolutionsShowcase />
      </Section>
      <Section>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <LatestStories data={latestStories} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SidebarArticles data={sidebarArticles} />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Box
          marginBottom={2}
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          padding={2}
          border="1px solid #ccc"
          borderRadius="4px"
          bgcolor="alternate.main"
        >
          <DescriptionCta
            title={<b>Looking to add an impact partner?</b>}
            subtitle="Add it to the database to share it with the Ftprint community."
            primaryCta={
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="/contribute-solutions/"
              >
                Add a solution
              </Button>
            }
            align="left"
          />
        </Box>
      </Section>
    </div>
  );
};

export default IndexView;
