import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { HeroShaped } from 'components/organisms';
import { Image } from 'components/atoms';
import BannerImage from 'assets/images/banner/home-banner0829.jpg';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  leftSideContent: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    },
  },
  heroShaped: {
    '& .hero-shaped__image': {
      backgroundColor: theme.palette.alternate.main,
    },
    [theme.breakpoints.down('sm')]: {
      '& .hero-shaped__image': {
        position: 'relative',
      },
      '& .hero-shaped__wrapper': {
        flexDirection: 'column',
      },
    },
  },
}));

const Hero = ({
  themeMode = 'light',
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight900}>
      Partnerships for a better world.
    </Typography>
  );

  const subtitle =
    'Break the inertia and join us in building the largest open-database of partners for positive business impact.';

  const contributeButton = (
    <Button
      size="large"
      variant="outlined"
      color="primary"
      component="a"
      href="/contribute-solutions"
    >
      Add a partner
    </Button>
  );

  const registerButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href="/all-solutions"
    >
      Browse partners
    </Button>
  );

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: 'h3',
        color: 'textPrimary',
      }}
      ctaGroup={[contributeButton, registerButton]}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
    />
  );
  return (
    <div className={className} {...rest}>
      <HeroShaped
        className={classes.heroShaped}
        leftSide={leftSideContent}
        rightSide={
          <Image
            src={BannerImage}
            alt="Banner image"
            style={{ objectFit: 'cover' }}
            lazy={false}
          />
        }
      />
    </div>
  );
};

export default Hero;
